<template>
  <div style="position: relative; height: 100%">
    <!-- 导航 -->
    <van-nav-bar title="商品详情" left-arrow @click-left="back" />
    <div style="background-color: white; margin-bottom: 1.3514rem">
      <van-swipe class="my-swipe" indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in allItemData.picUrls"
          :key="index"
        >
          <img style="width: 100%; height: 10.8108rem" :src="item" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="title">
        <span>{{ allItemData.name }}</span>
        <!-- 觉得我额加急发v吗厂家发v给vv看v开门 觉得我额加急发v吗厂家发v给vv看v开门 -->
      </div>
      <div class="price-content">
        <span>
          <span class="cMoney">￥</span>
          <span class="hMoney">{{ allItemData.priceDown }}</span>
          <span class="old">原价</span
          ><span class="old-price">￥{{ allItemData.priceUp }}</span>
          <span @click="getCouponList" class="coupon">领劵 > </span>
        </span>
        <span>
          <span class="haveSold">已售{{ allItemData.saleNum }}</span>
        </span>
      </div>
      <div>
        <div class="choose-spec" v-if="allItemData.spuSkuList.length == '1' ? false : true">
          <span class="spec-title">选择规格</span>
          <div class="middle"></div>
          <!-- :value="value" -->
          <van-field
            style="font-size: 0.3784rem"
            readonly
            clickable
            :value="speValue"
            placeholder="请选择规格"
            @click="chooseSpu"
          />
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
          <van-icon name="arrow" />
        </div>
        <div class="choose-address">
          <span class="spec-title" style="font-size: 0.3784rem">选择地址</span>
          <div class="middle"></div>
          <van-field
            style="font-size: 0.3784rem"
            readonly
            clickable
            :value="getAddress"
            placeholder="请选择收货地址"
            @click="chooseAddress"
          />
          <van-popup v-model="showPicker2" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns2"
              @cancel="showPicker2 = false"
              @confirm="onConfirm2"
            />
          </van-popup>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="shop">
        <img
          style="width: 1rem; height: 1rem"
          :src="allItemData.shopInfo.imgUrl"
        />
        <div class="real-shop">
          <span>{{ allItemData.shopInfo.name }}</span>
          <span class="shop-word">天猫</span>
          <!-- <img
            style="width: 0.3784rem; height: 0.3784rem"
            src='src="../../assets/img/takeOutFood/share.png"'
          /> -->
        </div>
        <span @click="enterShop" style="font-size: 0.3784rem">进入店铺</span>
        <van-icon
          style="margin-left: 0.2703rem; font-size: 0.3784rem"
          name="arrow"
        />
      </div>
      <div class="shop-info">
        <div
          style="
            border-right: 1px solid #ebedf0;
            font-size: 0.3784rem;
            margin-left: 0.3243rem;
          "
        >
          <span>宝贝描述</span>
          <span>{{ allItemData.score.goodsScoreAvg }}</span>
        </div>
        <div style="font-size: 0.3784rem">
          <span>卖家服务</span>
          <span>{{ allItemData.score.serviceScoreAvg }}</span>
        </div>
        <div
          style="
            border-left: 1px solid #ebedf0;
            font-size: 0.3784rem;
            margin-right: 0.3243rem;
          "
        >
          <span>物流服务</span>
          <span>{{ allItemData.score.logisticsScoreAvg }}</span>
        </div>
      </div>
      <div class="shop-items">
        <div
          class="item"
          @click="enterShopItem(item)"
          v-for="(item, index) in allItemData.shopInfo.listGoodsSpu"
          :key="index"
        >
          <div style="margin-top: 5px">
            <img
              style="width: 2.973rem; height: 2.7027rem"
              :src="item.picUrls[0]"
            />
          </div>
          <div
            class="desc"
            style="margin-top: 5%; width: 100%; font-size: 0.3243rem"
          >
            <!-- {{ item.name.length > 10 ? item.name.substr(0, 8) + '...' : item.name }} -->
            <span style="display: block">{{ item.name }}</span>
          </div>
          <span class="price" style="margin-left: -81px">
            <span class="cMoney" style="font-size: 0.3243rem">￥</span>
            <span class="hMoney" style="font-size: 0.4865rem">{{
              item.priceDown
            }}</span>
          </span>
        </div>
      </div>
      <!-- 商品详情 -->
      <div class="shop-detail">
        <div class="div1"></div>
        <div class="detail-title">
          <div class="title2">商品详情</div>
        </div>
        <div class="div2">
          <!-- <span v-html="allItemData.description"></span> -->
        </div>
      </div>
      <div class="bottom-img" style="margin-top: 10px; margin-bottom: 45px">
        <span v-html="allItemData.description"></span>
      </div>
      <!-- 加入购物车 -->
      <!-- <div>
        <van-goods-action>
          <van-goods-action-icon @click="enterShop" icon="shop-o" text="店铺" />
          <van-goods-action-icon
            @click="goCart"
            icon="shopping-cart-o"
            text="购物车"
          />
          <van-goods-action-button
            color="#313131"
            type="warning"
            text="加入购物车"
            @click="addCart"
          />
          <van-goods-action-button
            color="#F52556"
            type="danger"
            text="立即购买"
            @click="immediateBuy"
          />
        </van-goods-action>
      </div> -->

      <!-- 立即购买弹出的框 -->
      <van-action-sheet v-model="buyShow">
        <div class="content">
          <div class="buy-info">
            <div class="buy-img">
              <img :src="imgUrl" />
            </div>
            <div class="buy-price">
              <span style="color: red">￥{{ priceNow }}</span>
              <span :style="{ opacity: opacity ? 1 : 0 }"
                >库存：{{ stock }}</span
              >
              <span style="font-size: 12px">请选择：商品规格</span>
            </div>
            <van-icon
              name="close"
              style="margin-right: 10px"
              @click="closeBuy"
            />
          </div>
          <div class="spu-data" v-for="(item, index) in spuData" :key="index">
            <span class="spu-name">{{ item.name }}</span>
            <div class="small-name">
              <span
                :style="{
                  background:
                    numArr[index].num == index2 ? 'rgb(251, 43, 83)' : '',
                  color: numArr[index].num == index2 ? '#fff' : '',
                }"
                @click="clickSpu(index, index2)"
                v-for="(item2, index2) in item.goodsSpecValueList"
                :key="index2"
                >{{ item2.name }}</span
              >
            </div>
          </div>
          <div class="num">
            <br>
            <span>购买数量</span>
            <van-stepper v-model="buyValue" />
          </div>
          <div class="buy-btn">
            <van-button
              @click="goPay"
              round
              type="primary"
              color="rgb(251, 43, 83)"
              size="large"
              >{{ isPay }}</van-button
            >
          </div>
        </div>
      </van-action-sheet>
    </div>
    <div class="Ge-service" @click="contact(imAccount)">
      <van-icon class="Ge-service-icon" size="23" name="user-circle-o" />
      <span>客服</span>
    </div>
  </div>
</template>

<script>
import { geItemDetail, addCart } from "@/api/itemDetail";
import { contact } from "@/common";
import { Toast } from "vant";
export default {
  data() {
    return {
      // 规格
      value: "",
      showPicker: false,
      columns: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      //  地址
      value2: "",
      showPicker2: false,
      columns2: [
        "杭州2",
        "宁波2",
        "温州2",
        "绍兴2",
        "湖州2",
        "嘉兴2",
        "金华",
        "衢州",
      ],
      allItemData: {},
      buyShow: false, //
      buyValue: 1, //购买数量
      getAddress: "",
      spuData: [], //规格数据
      numArr: [],
      spuArr: [],
      speValue: "", //选中规格
      isPay: "", //判断是加入购物车还是购买
      spuIdArr: [],
      priceNow: null,
      imgUrl: null,
      imAccount: "",
      spuSkuList: [],
      stock: 0,
      volume: 0, // 体积
      weight: 0, // 重量
      opacity: false,
      skuId: null
    };
  },

  watch: {
    buyShow(value) {
      if (!value) {
        this.priceNow = this.allItemData.priceDown
        this.imgUrl = this.allItemData.picUrls[0]
        this.opacity = false;
        this.buyValue = 1;
      } else {
        if (!this.spuData.length) {
          this.opacity = true // 商品无规格时为了打开规格弹框有库存
          this.stock = this.allItemData.spuSkuList[0].stock
        }
      }
    },
    $route() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      if (this.$route.query.session) {
        this.$store.commit("setSession", this.$route.query.session);
      }
      this.value = sessionStorage.getItem("value");
      // 返回后获取的地址
      if (sessionStorage.getItem("address")) {
        this.getAddress = JSON.parse(sessionStorage.getItem("address"));
        this.getAddress =
          this.getAddress.provinceName +
          this.getAddress.cityName +
          this.getAddress.countyName;
      }
      this.geItemDetail();
    }
  },

  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
    this.speValue = localStorage.getItem("chooseSpu");
  },
  created() {
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.value = sessionStorage.getItem("value");
    // 返回后获取的地址
    if (sessionStorage.getItem("address")) {
      this.getAddress = JSON.parse(sessionStorage.getItem("address"));
      this.getAddress =
        this.getAddress.provinceName +
        this.getAddress.cityName +
        this.getAddress.countyName;
    }
    this.geItemDetail();
  },
  methods: {
    contact,

    // 店铺下面商品
    enterShopItem(item) {
      this.$router.push({
        path: "/itemDetail",
        query: {
          id: item.id,
          first: 0,
          session: this.$route.query.session
        },
      });
    },
    // 去领劵
    getCouponList() {
      if(!this.$route.query.session){
        this.Login();
        return;
      }
      this.$router.push({
        path: "/coupon",
        query: {
          spuId: this.allItemData.goodsSpuData[0].id,
          shopId: this.allItemData.id,
          first: 0,
        },
      });
    },
    // 返回上一页
    back() {
      if (this.$route.query.first == 0) {
        this.$router.back(-1);
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
      localStorage.removeItem("chooseSpu");
    },
    // 进入店铺
    enterShop() {
      this.$router.push({
        path: "/shopDetail",
        query: {
          id: this.allItemData.shopId,
          first: 0,
          session: this.$route.query.session
        },
      });
    },
    //  不加入购物车 不购买情况下选择规格
    chooseSpu() {
      this.speValue = "";
      this.isPay = "确定";
      this.buyShow = true;
      localStorage.removeItem("chooseSpu");
    },
    // 选择规格
    clickSpu(index, index2) {
      this.numArr[index].num = index2;
      this.spuArr[index] = this.spuData[index].goodsSpecValueList[index2].name;
      this.spuIdArr[index] =
        this.spuData[index].goodsSpecValueList[index2].specId;
      const spuArr = JSON.parse(JSON.stringify(this.spuArr));
      const spuSkuList = JSON.parse(JSON.stringify(this.spuSkuList));
      spuSkuList.forEach((x, y) => {
        if (JSON.stringify(spuArr) == JSON.stringify(x.specs)) {
          this.priceNow = spuSkuList[y].salesPrice;
          this.stock = spuSkuList[y].stock;
          this.volume = spuSkuList[y].volume;
          this.weight = spuSkuList[y].weight;
          this.skuId = spuSkuList[y].skuId
          this.imgUrl = spuSkuList[y].imgUrl
          this.opacity = true;
        }
      });
    },
    // 选择地址
    chooseAddress() {
      if(!this.$route.query.session){
        this.Login();
        return;
      }
      this.$router.push({
        path: "/shippingAddress",
        query: {
          first: 0,
        },
      });
    },
    // 去支付页面
    goPay() {
      const spuArr = JSON.parse(JSON.stringify(this.spuArr));
      const index = spuArr.findIndex((x) => x == 0);
      if (index != -1) {
        return Toast("请选择" + this.spuData[index].name);
      }

      if (this.isPay == "加入购物车") {
        if (this.spuData) { // 为空时表示这件商品你没有规格
          // 新加的
          for (let i = 0; i < this.spuData.length; i++) {
            this.spuArr[i] = this.spuData[i].name + ":" + this.spuArr[i];
          }
          if (this.spuArr.length >= 1) {
            let chooseSpu = this.spuArr.join("/");
            this.speValue = chooseSpu;
          } else {
            let chooseSpu = this.spuArr.join("");
            this.speValue = chooseSpu;
          }
        }
        if (this.stock == "0") {
          Toast("暂无库存 不能加入购物车");
          return;
        }
        let params = {
          shopId: this.allItemData.shopId,
          delFlag: "0",
          spuId: this.allItemData.id,
          skuId: this.skuId,
          quantity: this.buyValue,
          specInfo: this.speValue,
          addPrice: this.priceNow
        };
        addCart(params).then((res) => {
          if (res.data.code === 0) {
            this.$Toast("已添加购物车");
          }
        });
        this.buyShow = false;
      } else if (this.isPay == "提交订单") {
        if (this.spuData) { // 为空时表示这件商品你没有规格
          for (let i = 0; i < this.spuData.length; i++) {
            this.spuArr[i] = this.spuData[i].name + ":" + this.spuArr[i];
          }
          let chooseSpu = this.spuArr.join("/");
          this.speValue = chooseSpu;
          if (this.stock == "0") {
            Toast("暂无库存 不能提交订单");
            return;
          }
        }
        // 立即购买
        // if (!this.getAddress) {
        //   return this.$Toast("请先选择地址");
        // }
        this.$router.push({
          path: "/sureorder",
          query: {
            num: this.buyValue, // 购买数量
            value: this.speValue, // 规格 （重量:2kg/装箱:红果青果各一半/包装:保鲜箱）
            spuIdArr: this.spuIdArr,
            first: 0,
            volume: this.volume,
            weight: this.weight,
            id: this.$route.query.id,
            priceNow: this.priceNow,
            skuId: this.skuId
          },
        })
      } else {
        this.buyShow = false;
        for (let i = 0; i < this.spuData.length; i++) {
          this.spuArr[i] = this.spuData[i].name + ":" + this.spuArr[i];
        }
        if (this.spuArr.length >= 1) {
          let chooseSpu = this.spuArr.join("/");
          this.speValue = chooseSpu
        } else {
          let chooseSpu = this.spuArr.join("");
          this.speValue = chooseSpu
        }
      }
    },
    // 关闭购买页面
    closeBuy() {
      this.buyShow = false;
    },
    // 去购物车
    goCart() {
      if(!this.$route.query.session){
        this.Login();
        return;
      }
      this.$router.push({
        path: "/cart2",
        query: {
          first: 0,
        },
      });
    },
    // 商品详情
    geItemDetail() {
      let id = this.$route.query.id || "1536994077557596162";
      geItemDetail(id).then((res) => {
        if (res.data.code === 0) {
          this.allItemData = res.data.data;
          this.skuId = res.data.data.skus[0].id
          this.imAccount = res.data.data.imAccount;
          this.priceNow = this.allItemData.priceDown;
          this.imgUrl = this.allItemData.picUrls[0];
          if (res.data.data.goodsSpuData) {
            this.spuData = res.data.data.goodsSpuData;
          } else {
            this.data = []
            this.volume = res.data.data.spuSkuList[0].volume
            this.weight = res.data.data.spuSkuList[0].weight
          }
          this.spuSkuList = res.data.data.spuSkuList.map((x) => ({
            salesPrice: x.salesPrice,
            stock: x.stock,
            volume: x.volume,
            weight: x.weight,
            imgUrl: x.picUrl,
            skuId: x.specs[0].skuId,
            specs: x.specs.map((y) => y.specValueName),
          }));
    
          // 造加数据
          for (let i = 0; i < this.spuData.length; i++) {
            this.numArr.push({
              num: -1,
            });
          }
          this.spuArr = new Array(this.spuData.length).fill(0);
        }
      });
    },
    // rem
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    //   规格
    onConfirm(value) {
      sessionStorage.setItem("value", value);
      this.value = value;
      this.showPicker = false;
    },
    // 地址
    onConfirm2(value) {
      this.value2 = value;
      this.showPicker2 = false;
    },
    // 加入购物车
    addCart() {
       console.log('=========');
      if(!this.$route.query.session){
        this.Login();
        return;
      }
      this.isPay = "加入购物车";
      if (this.spuData) { // spuData 为空时表示这件商品没有规格
        this.spuArr = new Array(this.spuData.length).fill(0);
        // 初始化numArr 不然提交订单有bug
        this.numArr = [];
        for (let i = 0; i < this.spuData.length; i++) {
          this.numArr.push({
            num: -1,
          });
        }
      }
      this.buyShow = true;
    },
    // 立即购买
    immediateBuy() {
      this.isPay = "提交订单";
      if (this.spuData) { // spuData 为空时表示这件商品没有规格
        this.spuArr = new Array(this.spuData.length).fill(0);
        // 初始化numArr 不然提交订单有bug
        this.numArr = [];
        for (let i = 0; i < this.spuData.length; i++) {
          this.numArr.push({
            num: -1,
          });
        }
      }
      // if (!this.getAddress) {
      //   return this.$Toast("请先选择地址");
      // }
      this.buyShow = true;
    },
    //登录
    Login() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("login");
      }
      if (isAndroid) {
        window.android.login();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Ge-service {
  width: 42px;
  height: 42px;
  position: fixed;
  border: 1px solid #7e7e7e;
  background: #727073;
  border-radius: 999px;
  right: 10px;
  top: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  span {
    font-size: 12px;
  }
}

.Ge-service:active {
  background: #9d9c9e;
}
/deep/ .van-nav-bar {
  position: fixed;
  line-height: 1.2432rem;
  width: 100%;
  top: 0;
}
/deep/ .van-nav-bar__content {
  height: 1.2432rem;
}
/deep/ .van-icon-arrow-left::before {
  font-size: 0.5263rem;
}
/deep/ .van-nav-bar__title {
  font-size: 0.4324rem;
}
.title {
  padding: 0.2703rem;
  font-size: 0.3784rem;
}
.my-swipe .van-swipe-item {
  margin-top: 1.3514rem;
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.price-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2703rem;
  .cMoney {
    color: #fb2b53;
    font-size: 0.8108rem;
  }
  .hMoney {
    font-size: 0.8108rem;
    color: #fb2b53;
    margin-right: 0.2703rem;
  }
  .old {
    color: #999999;
    margin-right: 0.0811rem;
    font-size: 0.3784rem;
  }
  .old-price {
    color: #999999;
    text-decoration: line-through;
    margin-right: 0.2703rem;
    font-size: 0.3784rem;
  }
  .coupon {
    color: #fb2b53;
    font-size: 0.3784rem;
  }
  .haveSold {
    color: #999999;
    margin-right: 0.1351rem;
    font-size: 0.3784rem;
  }
}
.choose-spec {
  border-bottom: 1px solid #ebedf0;
  margin: 0px 0.4054rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .spec-title {
    display: inline-block;
    height: 100%;
    width: 2.4324rem;
    font-size: 0.3784rem;
  }
  .middle {
    width: 1px;
    height: 0.5405rem;
    margin-top: 0.0811rem;
    // background-color: #efefef;
  }
}
.van-cell::after {
  border-bottom: none !important;
}
.choose-spec {
  border-bottom: 1px solid #ebedf0;
  margin: 0px 0.4054rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .spec-title {
    display: inline-block;
    height: 100%;
    width: 2.4324rem;
    border-right: 1px solid #efefef;
  }
  .middle {
    width: 1px;
    height: 0.5405rem;
    margin-top: 0.0811rem;
    // background-color: #efefef;
    // background-color: pink;
  }
}
.choose-address {
  border-bottom: 1px solid #ebedf0;
  margin: 0px 0.4054rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .spec-title {
    display: inline-block;
    height: 100%;
    width: 2.4324rem;
    border-right: 1px solid #efefef;
  }
  .middle {
    width: 1px;
    height: 0.5405rem;
    margin-top: 0.0811rem;
    // background-color: #efefef;
    // background-color: pink;
  }
}
.shop {
  margin: 0.4054rem;
  display: flex;
  align-items: center;
  .real-shop {
    margin-left: 0.2703rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      color: #333333;
      font-family: bold;
      font-size: 0.4865rem;
    }
    .shop-word {
      display: inline-block;
      width: 30px;
      font-size: 12px;
      background-color: red;
      color: #fff;
      // margin: 0 auto;
      text-align: center;
      border-radius: 10px;
    }
  }
}
.shop-info {
  display: flex;
  justify-content: space-around;
  div {
    display: flex;
    justify-content: center;
    width: 33.3%;
    color: #999999;
    span:nth-child(2) {
      color: #f52556;
      margin-left: 0.1351rem;
    }
  }
}

.shop-items {
  // background-color: white;
  display: flex;
  margin: 0.5405rem 0.4054rem;
  .item {
    // background-color: #efefef;
    width: 33.3%;
    // height: 4.0541rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .price {
      color: #f52556;
    }
  }
}
.shop-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  .detail-title {
    color: #231815;
    font-size: 0.4324rem;
    font-weight: bold;
  }
  .div1 {
    width: 2.1622rem;
    height: 2px;
    margin-right: 0.2703rem;
    background-color: #231815;
  }
  .div2 {
    width: 2.1622rem;
    height: 2px;
    margin-left: 0.2703rem;
    background-color: #231815;
  }
}
.van-cell::after {
  border-bottom: none !important;
}
// /deep/ .van-picker {
//    font-size: 0.3784rem;
// }
/deep/ .van-icon-arrow::before {
  font-size: 0.3784rem;
}
/deep/ .van-picker__cancel {
  font-size: 0.3784rem;
}
/deep/ .van-picker__confirm {
  font-size: 0.3784rem;
}
/deep/ .van-picker__toolbar {
  margin-bottom: 0.1351rem;
}
/deep/.van-goods-action {
  height: 1.3514rem;
  margin-top: 20px;
}
/deep/ .van-goods-action-icon {
  min-width: 1.2973rem;
  font-size: 0.2703rem;
}
/deep/ .van-icon-shop-o::before {
  font-size: 0.4703rem;
}
/deep/ .van-icon-shopping-cart-o::before {
  font-size: 0.4703rem;
}
/deep/ .van-icon .van-icon-shop-o .van-goods-action-icon__icon {
  font-size: 0.4703rem;
}
/deep/.van-goods-action-button {
  height: 1.0811rem;
  font-size: 0.3784rem;
}
// 立即购买弹出的样式
.content {
  margin-top: 20px;
  .buy-info {
    display: flex;
    .buy-img {
      width: 120px;
      height: 120px;
      margin-left: 10px;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .buy-price {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .spu-data {
    margin-left: 10px;
    .spu-name {
      font-size: 14px;
      display: inline-block;
      margin-top: 8px;
    }
    .small-name {
      display: flex;
      margin-top: 8px;
      span {
        display: inline-block;
        background-color: #f1f1f1;
        padding: 3px 5px;
        margin-right: 8px;
        border-radius: 5px;
      }
    }
  }
  .num {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    margin-top: 20px;
  }
  .buy-btn {
    margin: 60px 10px 10px 10px;
  }
}
/deep/ .van-button--large {
  height: 40px;
}
</style>